export const UserRole = {
  Admin: { label: 'Admin', key: 'Admin' },
  SalesRepresentative: { label: 'Sales Representative', key: 'SalesRepresentative' },
  Standard: { label: 'Standard', key: 'Standard' },
} as const

export const UserRoleOptions = Object.keys(UserRole).map((key) => ({
  key: UserRole[key as UserRoleKey].key,
  label: UserRole[key as UserRoleKey].label,
}))

export function getRoleLabel(roleKey: UserRoleKey): string {
  return UserRole[roleKey].label
}

export type UserRoleKey = keyof typeof UserRole
