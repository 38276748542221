import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import { UserProfile } from 'service/generated/graphql'
import { deleteUser } from 'state/userList/slice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { SuccessToast, FailedToast } from 'component/Toast'
import { useDeleteUserMutation } from 'service/hooks/deleteUser'

type PropTypes = {
  user: UserProfile | null
  onRequestClose: () => void
}

const DeleteModal = ({ user, onRequestClose }: PropTypes) => {
  const [deleteUserMutation] = useDeleteUserMutation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const onPressDeleteUser = async () => {
    try {
      setLoading(true)
      await deleteUserMutation({ variables: { userId: user?.userId || '' } })
      dispatch(deleteUser(user?.userId || ''))
      onRequestClose()
      toast(<SuccessToast message='Delete user successfully' />)
    } catch (error) {
      toast(<FailedToast message='Failed to delete user' />)
      console.log('error delete user', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Modal show={!!user} onHide={onRequestClose} centered>
        <Modal.Header closeButton>
          <div className='w-100 text-center'>
            <Modal.Title>Delete User</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete {user?.email}?</Modal.Body>
        <Modal.Footer style={{ borderTop: 0 }}>
          <Button variant='secondary' onClick={onRequestClose}>
            CANCEL
          </Button>
          <Button variant='primary' onClick={onPressDeleteUser}>
            {loading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
                className='mx-2'
              />
            ) : (
              'DELETE'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteModal
