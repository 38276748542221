import { Navbar, Nav, NavDropdown, Container, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { selectMe } from 'state/user/selector'
import { logoImg } from 'assets/images'
import { useSelector } from 'react-redux'
import { signOut } from 'state/user/util'

const MenuBar = () => {
  const user = useSelector(selectMe)
 
  return (
    <Navbar bg='white' expand='lg' fixed='top'>
      <Container>
        <LinkContainer to={'/'}>
          <Navbar.Brand>
            <Image src={logoImg} style={{ width: 85, height: 22 }} />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <LinkContainer to={'/'}>
              <Nav.Link href='#dashboard'>Dashboard</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
            <NavDropdown title={`${user?.name}`} id='basic-nav-dropdown' align='end'>
              <NavDropdown.Item onClick={signOut}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default MenuBar
