import { CancelCircle, CheckCircle } from 'assets/icons'

type ToastProps = {
  message: string
}

export const SuccessToast = ({ message }: ToastProps) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <CheckCircle />
    <p className='m-0 ps-2'>{message}</p>
  </div>
)

export const FailedToast = ({ message }: ToastProps) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <CancelCircle id='error-svg' />
    <p className='m-0 ps-2'>{message}</p>
  </div>
)
