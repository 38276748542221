import { Filter as FilterIcon } from 'assets/icons'
import { forwardRef, LegacyRef, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import './index.css'

type OptionType = {
  key: string
  label: string
}
type FilterProps = {
  options: OptionType[]
  selectedOptions: string[]
  onOptionChanged?: (selectedOptions: string[]) => void
}

const Filter = ({ options, onOptionChanged = () => {}, selectedOptions = [] }: FilterProps) => {
  const allKeys = options.map((o) => o.key)
  const [show, setShow] = useState(false)

  const toggleRole = (key: string) => {
    const newOptions = selectedOptions.includes(key)
      ? selectedOptions.filter((r) => r !== key)
      : [...selectedOptions, key]
    onOptionChanged(newOptions)
  }

  const handleSelectAll = () => {
    if (selectedOptions.length !== options.length) {
      onOptionChanged(allKeys)
    } else {
      onOptionChanged([])
    }
  }

  return (
    <Dropdown
      id='role-filter'
      className='ms-2'
      show={show}
      onToggle={() => setShow((show) => !show)}
      autoClose='outside'
    >
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' />
      <Dropdown.Menu>
        <Dropdown.Item className='dropdown-item' as='button' onClick={handleSelectAll}>
          <Form.Check
            type='checkbox'
            label='All'
            checked={selectedOptions.length === options.length}
            readOnly
          />
        </Dropdown.Item>
        <Dropdown.Divider />
        {options.map((role) => (
          <Dropdown.Item
            className='dropdown-item'
            key={role.key}
            as='button'
            onClick={() => toggleRole(role.key)}
          >
            <Form.Check
              type='checkbox'
              label={role.label}
              checked={selectedOptions.includes(role.key)}
              readOnly
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const CustomToggle = forwardRef(
  (
    { onClick }: { onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void },
    ref: LegacyRef<SVGSVGElement>,
  ) => (
    <FilterIcon
      ref={ref}
      className='cursor-pointer'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    />
  ),
)

CustomToggle.displayName = 'CustomToggle'
Filter.displayName = 'Filter'

export default Filter
