import { useState } from 'react'
import { DeleteOutlined, EditOutlined } from 'assets/icons'
import DivCopy from 'component/DivCopy'
import { UserProfile } from 'service/generated/graphql'
import { UserStatus } from 'constant/Status'
import { getRoleLabel } from 'constant/Roles'

type UserItemProps = {
  user: UserProfile
  index: number
  onClickEdit: (user: UserProfile) => void
  onClickDelete: (user: UserProfile) => void
  isMe: boolean
}
export const UserItem = ({ user, index, isMe, onClickDelete, onClickEdit }: UserItemProps) => {
  const [showOption, setShowOption] = useState(false)
  return (
    <tr
      key={index}
      style={index % 2 ? { backgroundColor: 'rgb(248,248,248)' } : {}}
      onMouseEnter={() => setShowOption(true)}
      onMouseLeave={() => setShowOption(false)}
    >
      <td>
        <DivCopy className='info-text' copyText={user.name}>
          {user.name}
        </DivCopy>
      </td>
      <td>
        <DivCopy className='info-text' copyText={user.email}>
          {user.email}
        </DivCopy>
      </td>
      <td>
        <DivCopy className='info-text' copyText={user.salesRepresentativeId}>
          {user.salesRepresentativeId}
        </DivCopy>
      </td>

      <td>{user.role && getRoleLabel(user.role)}</td>

      <td>
        <span
          className='badge mt-2 mb-2'
          style={user.isEnabled ? { backgroundColor: '#4aad40' } : { backgroundColor: '#808080' }}
        >
          {user.isEnabled ? UserStatus.Active.key : UserStatus.Disabled.key}
        </span>
      </td>
      <td>
        {showOption && (
          <>
            <EditOutlined
              className='cursor-pointer'
              style={{ marginRight: '1rem' }}
              onClick={() => onClickEdit(user)}
              width={20}
            />
            {!isMe && (
              <DeleteOutlined
                className='cursor-pointer'
                onClick={() => onClickDelete(user)}
                width={20}
              />
            )}
          </>
        )}
      </td>
    </tr>
  )
}
