import { useState } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectMe } from 'state/user/selector'
import { selectUserList } from 'state/userList/selector'
import { UserProfile } from 'service/generated/graphql'
import DeleteModal from '../DeleteModal'
import UserItem from '../UserItem'
import Filter from 'component/Filter'
import { UserRoleKey, UserRoleOptions } from 'constant/Roles'
import './index.css'
import { PlaceholderItem } from './Placeholder'
import { FILTER_KEYS } from 'constant/FilterKey'
import { UserStatusKey, UserStatusOptions } from 'constant/Status'
import { Reset } from 'assets/icons'
import { FilterContent } from '../FilterBadge/FilterBadge'

type UserTableProps = {
  pageIndex: number
  onUpdateFilter: (key: string, value: UserRoleKey[] | string[]) => void
  onRefreshData: () => void
  loading: boolean
  filterValues: Record<string, FilterContent>
}

const EMPTY_ARRAY = Array(8).fill(0)

const UserTable = ({
  pageIndex,
  onUpdateFilter,
  onRefreshData,
  loading,
  filterValues,
}: UserTableProps) => {
  const navigate = useNavigate()
  const data = useSelector(selectUserList(pageIndex))
  const me = useSelector(selectMe)

  const onClickEdit = (user: UserProfile) => {
    navigate('/edit', {
      state: {
        userId: user.userId,
      },
    })
  }

  const [deleteUser, setDeleteUser] = useState<UserProfile | null>(null)

  const onClickDelete = (user: UserProfile) => {
    setDeleteUser(user)
  }

  const onCloseDeleteModal = () => {
    setDeleteUser(null)
  }

  const onUserStatusChange = (value: string[]) => {
    onUpdateFilter(FILTER_KEYS.isEnabled, value)
  }

  const onUserRoleChange = (value: UserRoleKey[]) => {
    onUpdateFilter(FILTER_KEYS.role, value)
  }

  const renderUserList = () => {
    if (data.length === 0) return <h4 style={{margin: 20}}>No user found</h4>
    return data.map((user, index) => (
      <UserItem
        key={user.userId}
        user={user}
        index={index}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
        isMe={me?.userId === user.userId}
      />
    ))
  }

  return (
    <div>
      <Table responsive={false} hover className='user-table'>
        <thead>
          <tr style={{ backgroundColor: 'rgb(248,248,248)' }}>
            <th className='col-2'>Name</th>
            <th className='col-3'>Email</th>
            <th className='col-3'>Sales ID</th>
            <th className='col-2'>
              <div style={{ display: 'flex' }}>
                Role
                <Filter
                  options={UserRoleOptions}
                  onOptionChanged={(e) => onUserRoleChange(e as UserRoleKey[])}
                  selectedOptions={filterValues[FILTER_KEYS.role] as UserRoleKey[]}
                />
              </div>
            </th>
            <th className='col-1'>
              <div style={{ display: 'flex' }}>
                Status
                <Filter
                  options={UserStatusOptions}
                  onOptionChanged={onUserStatusChange}
                  selectedOptions={filterValues[FILTER_KEYS.isEnabled] as UserStatusKey[]}
                />
              </div>
            </th>
            <th className='col-1'>
              <div
                style={{ display: 'flex', justifyContent: 'center' }}
                className='cursor-pointer'
                onClick={onRefreshData}
              >
                <Reset width={22} height={22} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading
            ? EMPTY_ARRAY.map((_, index) => <PlaceholderItem index={index} key={index} />)
            : renderUserList()}
        </tbody>
      </Table>
      <DeleteModal user={deleteUser} onRequestClose={onCloseDeleteModal} />
    </div>
  )
}

export default UserTable
