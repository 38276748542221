import { Pagination } from 'react-bootstrap'

type PaginationFooterProps = {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  isEndOfList: boolean
}

const PaginationFooter = ({
  currentPage,
  totalPages,
  onPageChange,
  isEndOfList,
}: PaginationFooterProps) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1)
  const nextPageDisabled = currentPage === totalPages && isEndOfList
  const prevPageDisabled = currentPage === 1
  return (
    <Pagination className='justify-content-center'>
      <Pagination.Prev
        style={{ opacity: prevPageDisabled ? 0 : 1 }}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={prevPageDisabled}
      />

      {pages.map((page) => (
        <Pagination.Item
          className='cursor-pointer'
          key={page}
          active={page === currentPage}
          onClick={() => onPageChange(page)}
        >
          {page}
        </Pagination.Item>
      ))}

      {!isEndOfList && <Pagination.Ellipsis />}

      <Pagination.Next
        style={{ opacity: nextPageDisabled ? 0 : 1 }}
        onClick={() =>onPageChange(currentPage + 1)}
        disabled={nextPageDisabled}
      />
    </Pagination>
  )
}

export default PaginationFooter
