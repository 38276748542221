import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { getUserAccessToken } from '../state/user/util'

// Create an HTTP link to the GraphQL endpoint
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
})

// Create an auth link to attach the access token to the headers
const authLink = setContext(async (_, { headers }) => {
  const token = await getUserAccessToken()
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '', // Attach the JWT access token
    },
  }
})

// Create the Apollo Client
export const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]), // Combine authLink and httpLink
  cache: new InMemoryCache(), // Configure in-memory cache
})
