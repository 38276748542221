import { createSlice } from '@reduxjs/toolkit'
import { UserListState } from './type'
import { UserProfile } from 'service/generated/graphql'

export const PAGINATION_LIMIT = 15
const INITIAL_STATE: UserListState = {
  data: [],
  paginationToken: null,
  total: 0,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    insertUserList: (state, action) => {
      state.data.push(
        ...action.payload.data.filter(
          (x: UserProfile | null) => !state.data.find((y) => y.userId === x?.userId),
        ),
      )
      state.paginationToken = action.payload.paginationToken
      state.total = action.payload.total
    },
    setUserList: (state, action) => {
      state.data = action.payload
    },
    resetUserList: () => {
      return INITIAL_STATE
    },
    deleteUser: (state, action) => {
      state.data = state.data.filter((user) => user.userId !== action.payload)
    },
    updateUser(state, action) {
      const index = state.data.findIndex((user) => user.userId === action.payload.userId)
      if (index !== -1) {
        state.data[index] = { ...action.payload }
      }
    },
  },
})

export const { insertUserList, resetUserList, deleteUser, updateUser, setUserList } =
  userSlice.actions

export default userSlice.reducer
