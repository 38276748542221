/* eslint-disable camelcase */
import { Auth } from 'aws-amplify'

const getUserAccessToken = async () => {
  try {
    const session = await Auth.currentSession()
    const accessToken = session.getAccessToken().getJwtToken()
    return accessToken
  } catch (error) {
    // token expired, force user to login again
    Auth.federatedSignIn({ provider: 'kaercher-login' } as any)
    console.error('Error getting access token:', error)
    return null
  }
}

export const signOut = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    console.error('Error signing out:', error)
  }
}

export { getUserAccessToken }
