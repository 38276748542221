import { Placeholder } from 'react-bootstrap'

type UserItemProps = {
  index: number
}
export const PlaceholderItem = ({ index }: UserItemProps) => {
  return (
    <tr
      key={index}
      style={index % 2 ? { backgroundColor: 'rgb(248,248,248)', height: 52 } : { height: 52 }}
    >
      <td>
        <Placeholder as='span' animation='glow'>
          <Placeholder xs={6} bg='secondary' />
        </Placeholder>
      </td>
      <td>
        <Placeholder as='span' animation='glow'>
          <Placeholder xs={6} bg='secondary' />
        </Placeholder>
      </td>
      <td>
        <Placeholder as='span' animation='glow'>
          <Placeholder xs={6} bg='secondary' />
        </Placeholder>
      </td>
      <td>
        <Placeholder as='span' animation='glow'>
          <Placeholder xs={6} bg='secondary' />
        </Placeholder>
      </td>
      <td>
        <Placeholder as='span' animation='glow'>
          <Placeholder xs={6} bg='secondary' />
        </Placeholder>
      </td>
      <td>
        <Placeholder as='span' animation='glow'></Placeholder>
      </td>
    </tr>
  )
}
