import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { PAGINATION_LIMIT } from './slice'

export const selectUserList = (pageIndex: number) =>
  createSelector(
    (state: RootState) => state.userList.data,
    (data) => data.slice((pageIndex - 1) * PAGINATION_LIMIT, pageIndex * PAGINATION_LIMIT),
  )

export const selectUserCount = (state: RootState) => state.userList.data.length
export const selectMaxUserAmount = (state: RootState) => state.userList.total


export const selectUserById = (userId: string) => (state: RootState) =>
  state.userList.data.find((user) => user.userId === userId)
export const selectPaginationToken = (state: RootState) => state.userList.paginationToken
