import { Cross } from 'assets/icons'
import { FILTER_KEYS } from 'constant/FilterKey'
import { getRoleLabel, UserRoleKey, UserRoleOptions } from 'constant/Roles'
import { UserStatusOptions } from 'constant/Status'
import { Badge, Stack } from 'react-bootstrap'

export type FilterContent = string | UserRoleKey[] | string[]

type FilterBadgeProps = {
  filterValues: Record<string, FilterContent>
  onRemoveFilter: (key: string) => void
}

const FilterBadge = ({ filterValues, onRemoveFilter }: FilterBadgeProps) => {
  const toFilterLabel = (key: string) => {
    switch (key) {
      case FILTER_KEYS.searchText:
        return `Search: ${filterValues[key]}`
      case FILTER_KEYS.role:
        if (filterValues[key].length === UserRoleOptions.length) {
          return 'Role: All'
        }
        return `Role: ${(filterValues[key] as UserRoleKey[]).map(getRoleLabel).join(', ')}`

      case FILTER_KEYS.isEnabled:
        if (filterValues[key].length === UserStatusOptions.length) {
          return 'Status: All'
        }
        return `Status: ${(filterValues[key] as string[]).join(', ')}`
    }
  }
  const renderKey = (key: string) => {
    if (!filterValues[key]) return null
    return (
      <Badge
        key={key}
        bg='primary'
        text='dark'
        style={{ paddingLeft: 12, display: 'flex', alignItems: 'center' }}
      >
        {toFilterLabel(key)}
        <Cross
          width={16}
          height={16}
          className='cursor-pointer'
          onClick={() => onRemoveFilter(key)}
        />
      </Badge>
    )
  }

  return (
    <>
      {Object.keys(filterValues).length > 0 && (
        <Stack direction='horizontal' gap={2}>
          {renderKey(FILTER_KEYS.searchText)}
          {renderKey(FILTER_KEYS.role)}
          {renderKey(FILTER_KEYS.isEnabled)}
        </Stack>
      )}
    </>
  )
}

export default FilterBadge
