import { ChangeEvent, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FailedToast, SuccessToast } from 'component/Toast'
import { selectUserById } from 'state/userList/selector'
import { updateUser } from 'state/userList/slice'
import { UserProfile, useAdminUpdateUserMutation } from 'service/generated/graphql'
import EmptyPage from './Empty'
import UpdateModal from './UpdateModal'
import './index.scss'
import { UserStatus, UserStatusOptions } from 'constant/Status'
import { selectMe } from 'state/user/selector'

const EditProfilePage = () => {
  const location = useLocation()
  const { userId } = location.state
  const dispatch = useDispatch()
  const me = useSelector(selectMe)

  const user = useSelector(selectUserById(userId))

  const isMe = user?.userId === me?.userId

  const [updateModalVisible, setUpdateModalVisible] = useState(false)

  const [loading, setLoading] = useState(false)

  const editedUser = useRef(user!)

  const [adminUpdateUser] = useAdminUpdateUserMutation()

  const hideConfirmModal = () => {
    setUpdateModalVisible(false)
  }

  const showConfirmModal = () => {
    const compareField = ['role', 'salesRepresentativeId', 'isEnabled'] as const
    if (compareField.some((field) => editedUser.current?.[field] !== user?.[field])) {
      setUpdateModalVisible(true)
    }
  }

  const handleSubmit = async () => {
    if (!editedUser.current) return

    try {
      const editData = editedUser.current

      setLoading(true)
      const payload: Partial<UserProfile> = {}

      if ((editData.salesRepresentativeId || '') !== (user?.salesRepresentativeId || '')) {
        payload.salesRepresentativeId = editData.salesRepresentativeId || ''
      }
      if (editData.role !== user?.role) {
        payload.role = editData?.role
      }
      if (editData.isEnabled !== user?.isEnabled) {
        payload.isEnabled = editData.isEnabled
      }
      const { data } = await adminUpdateUser({
        variables: {
          ...payload,
          userId: editData.userId!,
        },
      })

      if (!data?.adminUpdateUser?.isSuccess) {
        throw new Error(data?.adminUpdateUser?.reasons?.[0] || 'Failed to update user')
      }

      toast(<SuccessToast message='User updated successfully' />)
      dispatch(
        updateUser({
          ...user,
          ...editData,
        }),
      )

      onBackHome()
    } catch (error) {
      if (error instanceof Error) {
        toast(<FailedToast message={`Error message: ${error.message}`} />)
      } else {
        toast(<FailedToast message={'Failed to update user'} />)
      }
    } finally {
      hideConfirmModal()
      setLoading(false)
    }
  }

  const onBackHome = () => {
    history.back()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof UserProfile
    const value = e.target.value as any
    if (name !== 'isEnabled') {
      editedUser.current = { ...editedUser.current, [name]: value }
    } else {
      editedUser.current = { ...editedUser.current, isEnabled: value === UserStatus.Active.key }
    }
  }

  if (!user) return <EmptyPage />

  return (
    <Container>
      <p className='title mt-4'>USER INFORMATION</p>
      {/* <Summary user={user} /> */}

      <Form className='mt-2' onSubmit={handleSubmit}>
        <Row>
          <Col md='6'>
            <Row>
              <Form.Group controlId='Email'>
                <Form.Label>Email</Form.Label>
                <Form.Control type='text' disabled defaultValue={user.email || ''} />
              </Form.Group>
            </Row>

            <Row className='mt-2'>
              <Form.Group controlId='Name'>
                <Form.Label>Name</Form.Label>
                <Form.Control type='text' disabled defaultValue={user.name || ''} />
              </Form.Group>
            </Row>

            <Row className='mt-2'>
              <Form.Group controlId='salesId'>
                <Form.Label>Sales ID</Form.Label>
                <Form.Control
                  type='text'
                  name='salesRepresentativeId'
                  defaultValue={user.salesRepresentativeId || ''}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className='mt-2'>
              <Form.Group controlId='role'>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as='select'
                  defaultValue={user.role || ''}
                  style={{ fontSize: 12 }}
                  onChange={handleChange}
                  name='role'
                  // disabled={isMe}
                >
                  <option value='Admin'>Admin</option>
                  <option value='SalesRepresentative'>Sales Representative</option>
                  <option value='Standard'>Standard</option>
                </Form.Control>
              </Form.Group>
            </Row>

            <Row className='mt-2'>
              <Form.Group controlId='isEnabled'>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as='select'
                  name='isEnabled'
                  defaultValue={user.isEnabled ? UserStatus.Active.key : UserStatus.Disabled.key}
                  style={{ fontSize: 12 }}
                  onChange={handleChange}
                  disabled={isMe}
                >
                  {UserStatusOptions.map((status) => (
                    <option key={status.key} value={status.key}>
                      {status.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Col>
          <Button
            variant='secondary'
            className='mt-4'
            style={{ minWidth: 100 }}
            onClick={onBackHome}
          >
            {'<'} BACK
          </Button>
          <Button
            variant='primary'
            className='ms-4 mt-4'
            style={{ minWidth: 100 }}
            onClick={showConfirmModal}
          >
            UPDATE
          </Button>
        </Col>
      </Form>
      <UpdateModal
        visible={updateModalVisible}
        onRequestClose={hideConfirmModal}
        loading={loading}
        onConfirm={handleSubmit}
      />
    </Container>
  )
}

export default EditProfilePage
