import './App.css'
import './styles/custom-bootstrap.scss'
import './styles/modal.scss'
import './styles/toast.scss'
import './styles/variable.scss'

import AuthenticationPage from 'pages/Authentication'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import { Cross } from './assets/icons'
import About from './pages/About'
import Contact from './pages/Contact'
import EditProfilePage from './pages/EditProfile'
import Home from './pages/Home'
import MenuBar from './pages/MenuBar'
import { selectMe } from './state/user/selector'
import PageNotFound from './pages/PageNotFound'

const App: React.FC = () => {
  const user = useSelector(selectMe)

  if (!user) return <AuthenticationPage />

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
      }}
    >
      <MenuBar />

      {user.isDashboardAccessible ? (
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/edit' element={<EditProfilePage />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='*' element={<PageNotFound />}/>
        </Routes>
      ) : (
        <h3 className='text-center' style={{ position: 'absolute', top: 80, width: '100%' }}>
          Not Authorized
        </h3>
      )}

      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={5}
        theme='light'
        transition={Zoom}
        closeButton={Cross}
      />
    </div>
  )
}

export default App
