import { FILTER_KEYS } from 'constant/FilterKey'
import { UserStatus, UserStatusOptions } from 'constant/Status'
import { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { SearchUsersQuery, useSearchUsersLazyQuery } from 'service/generated/graphql'
import {
  selectMaxUserAmount,
  selectPaginationToken,
  selectUserCount,
} from 'state/userList/selector'
import { insertUserList, PAGINATION_LIMIT, resetUserList } from 'state/userList/slice'
import FilterBadge from './FilterBadge'
import { FilterContent } from './FilterBadge/FilterBadge'
import './index.css'
import PaginationFooter from './PaginationFooter'
import SearchBar from './SearchBar'
import UserTable from './UserTable'
import { UserRoleOptions } from 'constant/Roles'
import { useLocation, useNavigate } from 'react-router-dom'

const HomePage = () => {
  const dispatch = useDispatch()

  const [searchUser, { loading }] = useSearchUsersLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const navigate = useNavigate()

  const paginationToken = useSelector(selectPaginationToken)
  const userCount = useSelector(selectUserCount)
  const maxUserAmount = useSelector(selectMaxUserAmount)

  const location = useLocation()

  const getFilterFromParams = () => {
    const params = new URLSearchParams(location.search)
    const filterValues = params.get('filterValues')
    return JSON.parse(filterValues || '{}')
  }

  const [filterValues, setFilterValues] =
    useState<Record<string, FilterContent>>(getFilterFromParams)

  const refFilterValues = useRef(filterValues)
  const [pageIndex, setPageIndex] = useState(1)

  useEffect(() => {
    setFilterValues(getFilterFromParams())
  }, [location])

  useEffect(() => {
    // fetch when filter values change
    if (JSON.stringify(filterValues) !== JSON.stringify(refFilterValues.current)) {
      dispatch(resetUserList())
      setPageIndex(1)
      fetchMore(true)
    }
    refFilterValues.current = filterValues
  }, [filterValues])

  // init fetch
  useEffect(() => {
    if (userCount === 0) {
      fetchMore(true)
    }
  }, [])

  const updateFilterUrlParams = (values: Record<string, FilterContent>) => {
    const params = new URLSearchParams()
    params.set('filterValues', JSON.stringify(values))
    navigate(`?${params.toString()}`, { replace: false })
  }

  const maxPageIndex = Math.ceil(userCount / PAGINATION_LIMIT)
  const hasMore = userCount < maxUserAmount

  const fetchMore = async (initFetch: boolean = false) => {
    if (loading) return
    try {
      const searchParams: Record<string, any> = {
        limit: PAGINATION_LIMIT,
        paginationToken: initFetch ? null : paginationToken,
      }

      if (filterValues[FILTER_KEYS.searchText]) {
        searchParams['searchText'] = filterValues[FILTER_KEYS.searchText]
      }

      if (
        filterValues[FILTER_KEYS.role] &&
        filterValues[FILTER_KEYS.role].length < UserRoleOptions.length
      ) {
        searchParams['role'] = filterValues[FILTER_KEYS.role]
      }

      if (
        filterValues[FILTER_KEYS.isEnabled] &&
        filterValues[FILTER_KEYS.isEnabled].length < UserStatusOptions.length
      ) {
        searchParams['isEnabled'] =
          filterValues[FILTER_KEYS.isEnabled]?.[0] === UserStatus.Active.key
      }

      const { data } = await searchUser({
        variables: searchParams,
      })
      updateDataResponse(data)
    } catch (error) {
      console.error('fail to get list: ', error)
    }
  }

  const updateDataResponse = (data?: SearchUsersQuery) => {
    const list = data?.searchUsers?.data
    if (Array.isArray(list)) {
      const paginationToken = data?.searchUsers?.pagination?.paginationToken || null
      const total = data?.searchUsers?.pagination?.total || 0
      dispatch(insertUserList({ data: list, paginationToken, total }))
    }
  }

  const onRefreshData = async () => {
    if (loading) return
    dispatch(resetUserList())
    setPageIndex(1)
    fetchMore(true)
  }

  const onUpdateFilter = (key: string, value: FilterContent) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      const { [key]: _, ...rest } = filterValues
      updateFilterUrlParams(rest)
    } else {
      updateFilterUrlParams({ ...filterValues, [key]: value })
    }
  }

  const onRemoveFilter = (key: string) => {
    const { [key]: _, ...rest } = filterValues
    updateFilterUrlParams(rest)
  }

  const onPageChange = (pageIndex: number) => {
    if (loading) return
    if (pageIndex > maxPageIndex) {
      fetchMore()
    }
    setPageIndex(pageIndex)
  }

  return (
    <Container id='container'>
      <div id='header'>
        <SearchBar
          onSearch={(text) => {
            onUpdateFilter(FILTER_KEYS.searchText, text)
          }}
          searchFilterValue={filterValues[FILTER_KEYS.searchText] as string}
        />
        <div style={{ height: 40, display: 'flex', alignItems: 'center', position: 'relative' }}>
          <FilterBadge filterValues={filterValues} onRemoveFilter={onRemoveFilter} />
        </div>
      </div>

      <div id='content'>
        <UserTable
          pageIndex={pageIndex}
          onUpdateFilter={onUpdateFilter}
          onRefreshData={onRefreshData}
          loading={loading}
          filterValues={filterValues}
        />
      </div>
      <div id='footer'>
        <PaginationFooter
          currentPage={pageIndex}
          totalPages={maxPageIndex}
          onPageChange={onPageChange}
          isEndOfList={!hasMore}
        />
      </div>
    </Container>
  )
}

export default HomePage
