export const UserStatus = {
  Active: { label: 'Active', key: 'Active' },
  Disabled: { label: 'Disabled', key: 'Disabled' },
} as const

export const UserStatusOptions = Object.keys(UserStatus).map((key) => ({
  key: UserStatus[key as UserStatusKey].key,
  label: UserStatus[key as UserStatusKey].label,
}))

export function getStatusLabel(roleKey: UserStatusKey): string {
  return UserStatus[roleKey].label
}

export type UserStatusKey = keyof typeof UserStatus
