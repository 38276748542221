import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import './index.css'
import { Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type SearchBarProps = {
  onSearch: (text: string) => void
  searchFilterValue: string
}

const SearchBar = ({ onSearch, searchFilterValue }: SearchBarProps) => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')

  // clear search value when user remove filter
  useEffect(() => {
    if (!searchFilterValue) {
      setSearchValue('')
    }
  }, [searchFilterValue])

  const onSubmit = () => {
    onSearch(searchValue)
  }

  return (
    <div style={{ display: 'flex', marginTop: 16, minWidth: 300 }}>
      <InputGroup
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit()
          }
        }}
      >
        <Form.Control
          placeholder={'Name, Email or Sales Representative ID...'}
          className='text'
          value={searchValue}
          onFocus={(e) => e.target.select()}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </InputGroup>
      <Button variant='primary' style={{ marginLeft: 8, width: 120 }} onClick={onSubmit}>
        {t('search', 'Search')}
      </Button>
    </div>
  )
}

export default SearchBar
