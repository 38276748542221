import { toast } from 'react-toastify'
import { CopyContent } from 'assets/icons'

type DivCopyProps = {
  children: React.ReactNode
  copyText: string | null | undefined
  className?: string
} & React.HTMLAttributes<HTMLDivElement>
const DivCopy = ({ children, copyText, className = '' }: DivCopyProps) => {
  return (
    <div
      className={`cursor-pointer no-select ${className}`}
      onClick={() => {
        navigator.clipboard.writeText(copyText || '')
        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CopyContent width={24} height={24} />
            <p className='m-0 ms-2'>Copied to clipboard</p>
          </div>,
          { autoClose: 1500 },
        )
      }}
    >
      {children}
    </div>
  )
}

export default DivCopy
