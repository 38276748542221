import { Button, Container } from 'react-bootstrap'

const EmptyPage = () => (
  <Container>
    <h5 className='text-center'>User not found</h5>
    <Button
      variant='secondary'
      className='mt-4'
      style={{ minWidth: 100 }}
      onClick={history.back}
    >
      {'<'} BACK
    </Button>
  </Container>
)

export default EmptyPage