import { Button, Modal, Spinner } from 'react-bootstrap'

type PropTypes = {
  visible: boolean
  onRequestClose: () => void
  loading: boolean
  onConfirm: () => void
}

const UpdateModal = ({ loading, onRequestClose, visible, onConfirm }: PropTypes) => {
  return (
    <>
      <Modal show={visible} onHide={onRequestClose} centered>
        <Modal.Header closeButton>
          <div className='w-100 text-center'>
            <Modal.Title>Update User</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' style={{ minWidth: 80 }} onClick={onRequestClose}>
            CANCEL
          </Button>
          <Button
            variant='primary'
            style={{ minWidth: 80 }}
            onClick={() => {
              if (!loading) {
                onConfirm()
              }
            }}
          >
            {loading ? (
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            ) : (
              'UPDATE'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateModal
