import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/slice'
import userListReducer from './userList/slice'

const store = configureStore({
  reducer: {
    user: userReducer,
    userList: userListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
