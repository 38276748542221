import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// automatically redirect user to home
const PageNotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])
  return null
}
export default PageNotFound
