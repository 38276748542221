import { loadingImg } from 'assets/images'
import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useMyUserProfileLazyQuery } from 'service/generated/graphql'
import { setUser } from 'state/user/slice'

const AuthenticationPage = () => {
  const dispatch = useDispatch()
  const [fetch] = useMyUserProfileLazyQuery()

  const [loading, setLoading] = useState(true)

  const onSignIn = () => {
    Auth.federatedSignIn({ provider: 'kaercher-login' } as any)
  }

  useEffect(() => {
    const getUser = async () => {
      try {
        await Auth.currentAuthenticatedUser({ bypassCache: true })
        const { data } = await fetch()
        if (!data?.getUserMetadata || !data?.getUserProfile) {
          throw new Error('User not found')
        }
        dispatch(
          setUser({
            ...data?.getUserMetadata,
            ...data?.getUserProfile,
          }),
        )
      } catch (error) {
        console.log('not authenticated user')
      } finally {
        setLoading(false)
      }
    }
    getUser()
  }, [])

  return (
    <div className='text-center'>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <img src={loadingImg} style={{ width: 60, height: 60 }} />
        </div>
      ) : (
        <div style={{ padding: 20 }}>
          <Button onClick={onSignIn}>Sign In</Button>
        </div>
      )}
    </div>
  )
}

export default AuthenticationPage
