import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSJSON: { input: any; output: any; }
};

export type Accessory = {
  __typename?: 'Accessory';
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isStandardAccessory?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partnumberFormatted?: Maybe<Scalars['String']['output']>;
  priceGross?: Maybe<Scalars['String']['output']>;
  priceNet?: Maybe<Scalars['String']['output']>;
  techdata?: Maybe<Array<Maybe<TechData>>>;
};

export type AccessoryFilter = {
  __typename?: 'AccessoryFilter';
  filterableValues?: Maybe<Array<Maybe<AccessoryFilterableValue>>>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AccessoryFilterableValue = {
  __typename?: 'AccessoryFilterableValue';
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type AdminDeleteUserInput = {
  userId: Scalars['String']['input'];
};

export type AdminDeleteUserOutput = {
  __typename?: 'AdminDeleteUserOutput';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AdminUpdateUserInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<UserRole>;
  salesRepresentativeId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type AdminUpdateUserOutput = {
  __typename?: 'AdminUpdateUserOutput';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ButtonClose = {
  __typename?: 'ButtonClose';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
};

export type CategorizedSearchAccessories = {
  __typename?: 'CategorizedSearchAccessories';
  docCount?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<ProductItem>>>;
  pgCategories?: Maybe<Array<Maybe<PgCategory>>>;
};

export type CategorizedSearchContents = {
  __typename?: 'CategorizedSearchContents';
  docCount?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<SearchContent>>>;
};

export type CategorizedSearchDetergents = {
  __typename?: 'CategorizedSearchDetergents';
  docCount?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<ProductItem>>>;
  pgCategories?: Maybe<Array<Maybe<PgCategory>>>;
};

export type CategorizedSearchHg = {
  __typename?: 'CategorizedSearchHG';
  docCount?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<ProductItem>>>;
  pgCategories?: Maybe<Array<Maybe<PgCategory>>>;
};

export type CategorizedSearchOld = {
  __typename?: 'CategorizedSearchOld';
  docCount?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<ProductItem>>>;
  pgCategories?: Maybe<Array<Maybe<PgCategory>>>;
};

export type CategorizedSearchPro = {
  __typename?: 'CategorizedSearchPro';
  docCount?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<ProductItem>>>;
  pgCategories?: Maybe<Array<Maybe<PgCategory>>>;
};

export type CompareUserComparisonMetadataInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type CompareUserFavoriteMetadataInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type Content = {
  __typename?: 'Content';
  content?: Maybe<Array<Maybe<PageContent>>>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  export?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Country = {
  __typename?: 'Country';
  countryCode?: Maybe<Scalars['String']['output']>;
  languageCode?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type CreateFavoriteFolderDataOutput = {
  __typename?: 'CreateFavoriteFolderDataOutput';
  folder: FavoriteFolder;
  versionId: Scalars['String']['output'];
};

export type CreateFavoriteFolderInput = {
  countryCode: Scalars['String']['input'];
  folderName: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  versionId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFavoriteFolderOutput = {
  __typename?: 'CreateFavoriteFolderOutput';
  data?: Maybe<CreateFavoriteFolderDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type CreateFavoriteItemDataOutput = {
  __typename?: 'CreateFavoriteItemDataOutput';
  item: FavoriteItem;
  versionId: Scalars['String']['output'];
};

export type CreateFavoriteItemInput = {
  countryCode: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
  itemName: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  parameter?: InputMaybe<FavoriteItemParameterInput>;
  payload: FavoriteItemPayloadInput;
  type: FavoriteItemTypeEnum;
  versionId: Scalars['String']['input'];
};

export type CreateFavoriteItemOutput = {
  __typename?: 'CreateFavoriteItemOutput';
  data?: Maybe<CreateFavoriteItemDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type DeleteFavoriteFolderDataOutput = {
  __typename?: 'DeleteFavoriteFolderDataOutput';
  versionId: Scalars['String']['output'];
};

export type DeleteFavoriteFolderInput = {
  countryCode: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type DeleteFavoriteFolderOutput = {
  __typename?: 'DeleteFavoriteFolderOutput';
  data?: Maybe<DeleteFavoriteFolderDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type DeleteFavoriteItemDataOutput = {
  __typename?: 'DeleteFavoriteItemDataOutput';
  versionId: Scalars['String']['output'];
};

export type DeleteFavoriteItemInput = {
  countryCode: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type DeleteFavoriteItemOutput = {
  __typename?: 'DeleteFavoriteItemOutput';
  data?: Maybe<DeleteFavoriteItemDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Detergent = {
  __typename?: 'Detergent';
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partnumberFormatted?: Maybe<Scalars['String']['output']>;
  priceGross?: Maybe<Scalars['String']['output']>;
  priceNet?: Maybe<Scalars['String']['output']>;
};

export type Document = {
  __typename?: 'Document';
  type?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Array<Maybe<Url>>>;
};

export type DuplicateFavoriteFolderDataOutput = {
  __typename?: 'DuplicateFavoriteFolderDataOutput';
  folders: Array<FavoriteFolder>;
  items: Array<FavoriteItem>;
  versionId: Scalars['String']['output'];
};

export type DuplicateFavoriteFolderInput = {
  countryCode: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type DuplicateFavoriteFolderOutput = {
  __typename?: 'DuplicateFavoriteFolderOutput';
  data?: Maybe<DuplicateFavoriteFolderDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type DuplicateFavoriteItemDataOutput = {
  __typename?: 'DuplicateFavoriteItemDataOutput';
  item: FavoriteItem;
  versionId: Scalars['String']['output'];
};

export type DuplicateFavoriteItemInput = {
  countryCode: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type DuplicateFavoriteItemOutput = {
  __typename?: 'DuplicateFavoriteItemOutput';
  data?: Maybe<DuplicateFavoriteItemDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Equipment = {
  __typename?: 'Equipment';
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum ErrorCodeEnum {
  BadInputError = 'BAD_INPUT_ERROR',
  ComparisonVersionConflicts = 'COMPARISON_VERSION_CONFLICTS',
  FavoriteVersionConflicts = 'FAVORITE_VERSION_CONFLICTS',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotFoundError = 'NOT_FOUND_ERROR',
  ValidationError = 'VALIDATION_ERROR'
}

export type FavoriteFolder = {
  __typename?: 'FavoriteFolder';
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  rootLines: Array<Maybe<Scalars['String']['output']>>;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type FavoriteItem = {
  __typename?: 'FavoriteItem';
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  folderId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parameter?: Maybe<FavoriteItemParameter>;
  payload: FavoriteItemPayload;
  rootLines: Array<Maybe<Scalars['String']['output']>>;
  type: FavoriteItemTypeEnum;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type FavoriteItemParameter = {
  __typename?: 'FavoriteItemParameter';
  selectedGroups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum FavoriteItemParameterEnum {
  Accessories = 'ACCESSORIES'
}

export type FavoriteItemParameterInput = {
  selectedGroups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<FavoriteItemParameterEnum>;
};

export type FavoriteItemPayload = {
  __typename?: 'FavoriteItemPayload';
  pageId?: Maybe<Scalars['Int']['output']>;
  productIds?: Maybe<Array<Scalars['Int']['output']>>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type FavoriteItemPayloadInput = {
  pageId?: InputMaybe<Scalars['Int']['input']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export enum FavoriteItemTypeEnum {
  Comparison = 'comparison',
  Manualcontent = 'manualcontent',
  Productdetails = 'productdetails',
  Productgrouplist = 'productgrouplist',
  Productgroupoverview = 'productgroupoverview',
  Productlist = 'productlist'
}

export type FeatureBenefit = {
  __typename?: 'FeatureBenefit';
  benifits?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  feature?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
};

export type FilterableItems = {
  __typename?: 'FilterableItems';
  material?: Maybe<AccessoryFilter>;
  standardNominalSize?: Maybe<AccessoryFilter>;
};

export type FlatListCountryByYearInput = {
  year: Scalars['Int']['input'];
};

export type FolderSummary = {
  __typename?: 'FolderSummary';
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  folderId?: Maybe<Scalars['String']['output']>;
  language: Scalars['String']['output'];
  subFolderIds: Array<Maybe<Scalars['String']['output']>>;
  subFolders: Array<Maybe<FavoriteFolder>>;
  subItemIds: Array<Maybe<Scalars['String']['output']>>;
  subItems: Array<Maybe<FavoriteItem>>;
  updatedAt: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type GetAllUserFavoriteInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
};

export type GetAllUserFavoriteOutput = {
  __typename?: 'GetAllUserFavoriteOutput';
  country: Scalars['String']['output'];
  folders: Array<FavoriteFolder>;
  items: Array<FavoriteItem>;
  language: Scalars['String']['output'];
  versionId?: Maybe<Scalars['String']['output']>;
};

export type GetAssetExportInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  type: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type GetAssetExportResponse = {
  __typename?: 'GetAssetExportResponse';
  URL?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GetCountryListByYearInput = {
  year: Scalars['Int']['input'];
};

export type GetCountryListByYearResponse = {
  __typename?: 'GetCountryListByYearResponse';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<OldCountryType>>>;
  export?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type GetDownloadExportInput = {
  countryCode: Scalars['String']['input'];
  isMobile: Scalars['Boolean']['input'];
  languageCode: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type GetDownloadExportResponse = {
  __typename?: 'GetDownloadExportResponse';
  AK_DCA_COMMON?: Maybe<Scalars['String']['output']>;
  AK_DCA_HOME_AND_GARDEN?: Maybe<Scalars['String']['output']>;
  AK_DCA_PROFESSIONAL?: Maybe<Scalars['String']['output']>;
};

export type GetFolderSummaryInput = {
  countryCode: Scalars['String']['input'];
  folderId?: InputMaybe<Scalars['String']['input']>;
  languageCode: Scalars['String']['input'];
};

export type GetFontUrlResponse = {
  __typename?: 'GetFontUrlResponse';
  CLANPRO_NARRBOOK: Scalars['String']['output'];
  CLANPRO_NARRBOOK_BOLD: Scalars['String']['output'];
  TICK_IMG_URL: Scalars['String']['output'];
};

export type GetImageAssetPresignedUrlsInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  types: Array<InputMaybe<OfflineFileType>>;
  year: Scalars['Int']['input'];
};

export type GetImageChangeLogPresignedUrlInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  type: OfflineFileType;
  year: Scalars['Int']['input'];
};

export type GetOnboardingInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type GetOnboardingResponse = {
  __typename?: 'GetOnboardingResponse';
  productGroupId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
};

export type GetPageContentInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  pageId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type GetPdfAssetPresignedUrlsInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  types: Array<InputMaybe<OfflineFileType>>;
  year: Scalars['Int']['input'];
};

export type GetPdfChangeLogPresignedUrlInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  type: OfflineFileType;
  year: Scalars['Int']['input'];
};

export type GetProductByIdInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  productId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type GetProductComparisonInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
};

export type GetProductGroupByIdInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  productGroupId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type GetProductIdByPartNumberInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  partnumber: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type GetProductIdByPartNumberResponse = {
  __typename?: 'GetProductIdByPartNumberResponse';
  id?: Maybe<Scalars['String']['output']>;
};

export type GetProductListByIdInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  productGroupId: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type GetProductListByRootLineInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  rootLineIds: Array<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type GetProductListByRootlineResponse = {
  __typename?: 'GetProductListByRootlineResponse';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Product>>>;
  export?: Maybe<Scalars['String']['output']>;
  filterableItems?: Maybe<FilterableItems>;
  language?: Maybe<Scalars['String']['output']>;
  pagination?: Maybe<Pagination>;
  productGroupData?: Maybe<Scalars['String']['output']>;
  productGroupId?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type GetStructureInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type GetStructureResponse = {
  __typename?: 'GetStructureResponse';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Structure>>>;
  export?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type GetSubProductGroupByIdsInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  productGroupIds: Array<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type GetSubProductGroupByIdsResponse = {
  __typename?: 'GetSubProductGroupByIdsResponse';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Structure>>>;
  export?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type GetTranslationsInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type GetUserComparisonMetadataInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
};

export type GetUserFavoriteMetadataInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
};

export type Icon = {
  __typename?: 'Icon';
  attribute?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Array<Maybe<Url>>>;
};

export type IsAdobeAnalyticsAvailableInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
};

export type IsProductFinderAvailableInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
};

export type IsProductGroupHasProductFinderInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  productGroupId: Scalars['Int']['input'];
};

export type ListUserOutput = {
  __typename?: 'ListUserOutput';
  data?: Maybe<Array<Maybe<UserProfile>>>;
  pagination?: Maybe<Pagination>;
};

export type MoveFavoriteFolderDataOutput = {
  __typename?: 'MoveFavoriteFolderDataOutput';
  versionId: Scalars['String']['output'];
};

export type MoveFavoriteFolderInput = {
  countryCode: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  newParentFolderId: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type MoveFavoriteFolderOutput = {
  __typename?: 'MoveFavoriteFolderOutput';
  data?: Maybe<MoveFavoriteFolderDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type MoveFavoriteItemDataOutput = {
  __typename?: 'MoveFavoriteItemDataOutput';
  item: FavoriteItem;
  versionId: Scalars['String']['output'];
};

export type MoveFavoriteItemInput = {
  countryCode: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  newFolderId: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type MoveFavoriteItemOutput = {
  __typename?: 'MoveFavoriteItemOutput';
  data?: Maybe<MoveFavoriteItemDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminDeleteUser?: Maybe<AdminDeleteUserOutput>;
  adminUpdateUser?: Maybe<AdminUpdateUserOutput>;
  createFavoriteFolder?: Maybe<CreateFavoriteFolderOutput>;
  createFavoriteItem?: Maybe<CreateFavoriteItemOutput>;
  deleteFavoriteFolder?: Maybe<DeleteFavoriteFolderOutput>;
  deleteFavoriteItem?: Maybe<DeleteFavoriteItemOutput>;
  duplicateFavoriteFolder?: Maybe<DuplicateFavoriteFolderOutput>;
  duplicateFavoriteItem?: Maybe<DuplicateFavoriteItemOutput>;
  moveFavoriteFolder?: Maybe<MoveFavoriteFolderOutput>;
  moveFavoriteItem?: Maybe<MoveFavoriteItemOutput>;
  renameFavoriteFolder?: Maybe<RenameFavoriteFolderOutput>;
  renameFavoriteItem?: Maybe<RenameFavoriteItemOutput>;
  saveProductComparison?: Maybe<SaveProductComparisonOutput>;
  syncProductComparison?: Maybe<SyncProductComparisonOutput>;
  syncUserFavorite?: Maybe<SyncUserFavoriteOutput>;
  updateUserSalesRepresentativeId?: Maybe<UpdateUserSalesRepresentativeIdOutput>;
  userSelfDelete?: Maybe<UserSelfDeleteOutput>;
};


export type MutationAdminDeleteUserArgs = {
  input?: InputMaybe<AdminDeleteUserInput>;
};


export type MutationAdminUpdateUserArgs = {
  input?: InputMaybe<AdminUpdateUserInput>;
};


export type MutationCreateFavoriteFolderArgs = {
  input?: InputMaybe<CreateFavoriteFolderInput>;
};


export type MutationCreateFavoriteItemArgs = {
  input?: InputMaybe<CreateFavoriteItemInput>;
};


export type MutationDeleteFavoriteFolderArgs = {
  input?: InputMaybe<DeleteFavoriteFolderInput>;
};


export type MutationDeleteFavoriteItemArgs = {
  input?: InputMaybe<DeleteFavoriteItemInput>;
};


export type MutationDuplicateFavoriteFolderArgs = {
  input?: InputMaybe<DuplicateFavoriteFolderInput>;
};


export type MutationDuplicateFavoriteItemArgs = {
  input?: InputMaybe<DuplicateFavoriteItemInput>;
};


export type MutationMoveFavoriteFolderArgs = {
  input?: InputMaybe<MoveFavoriteFolderInput>;
};


export type MutationMoveFavoriteItemArgs = {
  input?: InputMaybe<MoveFavoriteItemInput>;
};


export type MutationRenameFavoriteFolderArgs = {
  input?: InputMaybe<RenameFavoriteFolderInput>;
};


export type MutationRenameFavoriteItemArgs = {
  input?: InputMaybe<RenameFavoriteItemInput>;
};


export type MutationSaveProductComparisonArgs = {
  input?: InputMaybe<SaveProductComparisonInput>;
};


export type MutationSyncProductComparisonArgs = {
  input?: InputMaybe<SyncProductComparisonInput>;
};


export type MutationSyncUserFavoriteArgs = {
  input?: InputMaybe<SyncUserFavoriteInput>;
};


export type MutationUpdateUserSalesRepresentativeIdArgs = {
  input?: InputMaybe<UpdateUserSalesRepresentativeId>;
};


export type MutationUserSelfDeleteArgs = {
  input?: InputMaybe<UserSelfDeleteInput>;
};

export enum OfflineFileType {
  Common = 'COMMON',
  HomeAndGarden = 'HOME_AND_GARDEN',
  Professional = 'PROFESSIONAL'
}

export type OldCountryType = {
  __typename?: 'OldCountryType';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  export?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Array<Maybe<OldLanguageType>>>;
  name?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type OldLanguageType = {
  __typename?: 'OldLanguageType';
  languageCode?: Maybe<Scalars['String']['output']>;
  languageName?: Maybe<Scalars['String']['output']>;
};

export type PgCategory = {
  __typename?: 'PGCategory';
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Pg_Image = {
  __typename?: 'PG_Image';
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Pg_Text = {
  __typename?: 'PG_Text';
  headline?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<Pg_Image>>>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PageContent = {
  __typename?: 'PageContent';
  config?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum PageContentTypeEnum {
  Content = 'content',
  Productdetails = 'productdetails',
  Productgrouplist = 'productgrouplist',
  Productgroupoverview = 'productgroupoverview',
  Productlist = 'productlist'
}

export type Pagination = {
  __typename?: 'Pagination';
  limit?: Maybe<Scalars['Int']['output']>;
  paginationToken?: Maybe<Scalars['String']['output']>;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
};

export type PopUpInfo = {
  __typename?: 'PopUpInfo';
  buttonClose?: Maybe<ButtonClose>;
  content?: Maybe<Scalars['AWSJSON']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PopUpInfoNotification = {
  __typename?: 'PopUpInfoNotification';
  data?: Maybe<PopUpInfo>;
  id?: Maybe<Scalars['Int']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
};

export type PopUpInfoNotificationInput = {
  locale: Scalars['String']['input'];
};

export type Product = {
  __typename?: 'Product';
  accessories?: Maybe<Array<Maybe<Accessory>>>;
  businessUnit?: Maybe<Scalars['String']['output']>;
  detergents?: Maybe<Array<Maybe<Detergent>>>;
  documents?: Maybe<Array<Maybe<Document>>>;
  ean?: Maybe<Scalars['String']['output']>;
  equipment?: Maybe<Array<Maybe<Equipment>>>;
  featureBenefits?: Maybe<Array<Maybe<FeatureBenefit>>>;
  filterValues?: Maybe<Scalars['AWSJSON']['output']>;
  icons?: Maybe<Array<Maybe<Icon>>>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  name?: Maybe<Scalars['String']['output']>;
  partnumber?: Maybe<Scalars['String']['output']>;
  partnumberFormatted?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  priceFormatted?: Maybe<Scalars['String']['output']>;
  priceGross?: Maybe<Scalars['String']['output']>;
  priceGrossFormatted?: Maybe<Scalars['String']['output']>;
  priceNet?: Maybe<Scalars['String']['output']>;
  priceNetFormatted?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  productgroupId?: Maybe<Scalars['Int']['output']>;
  productgroupPosition?: Maybe<Scalars['Int']['output']>;
  rootline?: Maybe<Array<Maybe<Rootline>>>;
  techdata?: Maybe<Array<Maybe<TechData>>>;
  texts?: Maybe<Array<Maybe<Text>>>;
};

export type ProductComparison = {
  __typename?: 'ProductComparison';
  country: Scalars['String']['output'];
  language: Scalars['String']['output'];
  productIds: Array<Scalars['Int']['output']>;
  userId: Scalars['String']['output'];
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  texts?: Maybe<Array<Maybe<Pg_Text>>>;
};

export type ProductGroupWithDropdown = {
  __typename?: 'ProductGroupWithDropdown';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<ProductGroup>>>;
  dropdown?: Maybe<Scalars['String']['output']>;
  export?: Maybe<Scalars['String']['output']>;
  isDropdownAvailable?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type ProductItem = {
  __typename?: 'ProductItem';
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  name?: Maybe<Scalars['String']['output']>;
  partnumber?: Maybe<Scalars['String']['output']>;
  partnumberFormatted?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  priceFormatted?: Maybe<Scalars['String']['output']>;
  priceGross?: Maybe<Scalars['String']['output']>;
  priceGrossFormatted?: Maybe<Scalars['String']['output']>;
  priceNet?: Maybe<Scalars['String']['output']>;
  priceNetFormatted?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  productgroupId?: Maybe<Scalars['Int']['output']>;
  rootline_updated?: Maybe<Scalars['String']['output']>;
  texts?: Maybe<Array<Maybe<Text>>>;
};

export type ProductList = {
  __typename?: 'ProductList';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Product>>>;
  export?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  pagination?: Maybe<Pagination>;
  productGroupData?: Maybe<Scalars['String']['output']>;
  productGroupId?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  compareUserComparisonMetadata?: Maybe<Scalars['Boolean']['output']>;
  compareUserFavoriteMetadata?: Maybe<Scalars['Boolean']['output']>;
  flatListCountryByYear?: Maybe<Array<Maybe<Country>>>;
  getAllUserFavorite?: Maybe<GetAllUserFavoriteOutput>;
  getAssetExport?: Maybe<GetAssetExportResponse>;
  getCountryListByYear?: Maybe<GetCountryListByYearResponse>;
  getDownloadExport?: Maybe<GetDownloadExportResponse>;
  getFolderSummary?: Maybe<FolderSummary>;
  getFontUrl?: Maybe<GetFontUrlResponse>;
  getImageAssetPresignedUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getImageChangeLogPresignedUrl?: Maybe<Scalars['String']['output']>;
  getOnboarding?: Maybe<GetOnboardingResponse>;
  getPageContent?: Maybe<Content>;
  getPdfAssetPresignedUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getPdfChangeLogPresignedUrl?: Maybe<Scalars['String']['output']>;
  getProductById?: Maybe<StringifyProduct>;
  getProductComparison?: Maybe<ProductComparison>;
  getProductGroupById?: Maybe<ProductGroupWithDropdown>;
  getProductIdByPartnumber?: Maybe<GetProductIdByPartNumberResponse>;
  getProductListById?: Maybe<ProductList>;
  getProductListByRootline?: Maybe<GetProductListByRootlineResponse>;
  getStructure?: Maybe<GetStructureResponse>;
  getSubProductGroupByIds?: Maybe<GetSubProductGroupByIdsResponse>;
  getTranslations?: Maybe<Translation>;
  getUserComparisonMetadata?: Maybe<UserComparisonMetadata>;
  getUserFavoriteMetadata?: Maybe<UserFavoriteMetadata>;
  getUserMetadata?: Maybe<UserMetadata>;
  getUserProfile?: Maybe<UserProfile>;
  isAdobeAnalyticsAvailable?: Maybe<Scalars['Boolean']['output']>;
  isProductFinderAvailable?: Maybe<Scalars['Boolean']['output']>;
  isProductGroupHasProductFinder?: Maybe<Scalars['Boolean']['output']>;
  listUser?: Maybe<ListUserOutput>;
  ping?: Maybe<Scalars['String']['output']>;
  search?: Maybe<Search>;
  searchSuggestions?: Maybe<SearchSuggestions>;
  searchUsers?: Maybe<SearchUsersOutput>;
  userNotifications?: Maybe<UserNotifications>;
};


export type QueryCompareUserComparisonMetadataArgs = {
  input?: InputMaybe<CompareUserComparisonMetadataInput>;
};


export type QueryCompareUserFavoriteMetadataArgs = {
  input?: InputMaybe<CompareUserFavoriteMetadataInput>;
};


export type QueryFlatListCountryByYearArgs = {
  input?: InputMaybe<FlatListCountryByYearInput>;
};


export type QueryGetAllUserFavoriteArgs = {
  input?: InputMaybe<GetAllUserFavoriteInput>;
};


export type QueryGetAssetExportArgs = {
  input?: InputMaybe<GetAssetExportInput>;
};


export type QueryGetCountryListByYearArgs = {
  input?: InputMaybe<GetCountryListByYearInput>;
};


export type QueryGetDownloadExportArgs = {
  input?: InputMaybe<GetDownloadExportInput>;
};


export type QueryGetFolderSummaryArgs = {
  input?: InputMaybe<GetFolderSummaryInput>;
};


export type QueryGetImageAssetPresignedUrlsArgs = {
  input?: InputMaybe<GetImageAssetPresignedUrlsInput>;
};


export type QueryGetImageChangeLogPresignedUrlArgs = {
  input?: InputMaybe<GetImageChangeLogPresignedUrlInput>;
};


export type QueryGetOnboardingArgs = {
  input?: InputMaybe<GetOnboardingInput>;
};


export type QueryGetPageContentArgs = {
  input: GetPageContentInput;
};


export type QueryGetPdfAssetPresignedUrlsArgs = {
  input?: InputMaybe<GetPdfAssetPresignedUrlsInput>;
};


export type QueryGetPdfChangeLogPresignedUrlArgs = {
  input?: InputMaybe<GetPdfChangeLogPresignedUrlInput>;
};


export type QueryGetProductByIdArgs = {
  input?: InputMaybe<GetProductByIdInput>;
};


export type QueryGetProductComparisonArgs = {
  input?: InputMaybe<GetProductComparisonInput>;
};


export type QueryGetProductGroupByIdArgs = {
  input?: InputMaybe<GetProductGroupByIdInput>;
};


export type QueryGetProductIdByPartnumberArgs = {
  input?: InputMaybe<GetProductIdByPartNumberInput>;
};


export type QueryGetProductListByIdArgs = {
  input?: InputMaybe<GetProductListByIdInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetProductListByRootlineArgs = {
  input?: InputMaybe<GetProductListByRootLineInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetStructureArgs = {
  input?: InputMaybe<GetStructureInput>;
};


export type QueryGetSubProductGroupByIdsArgs = {
  input?: InputMaybe<GetSubProductGroupByIdsInput>;
};


export type QueryGetTranslationsArgs = {
  input?: InputMaybe<GetTranslationsInput>;
};


export type QueryGetUserComparisonMetadataArgs = {
  input?: InputMaybe<GetUserComparisonMetadataInput>;
};


export type QueryGetUserFavoriteMetadataArgs = {
  input?: InputMaybe<GetUserFavoriteMetadataInput>;
};


export type QueryIsAdobeAnalyticsAvailableArgs = {
  input?: InputMaybe<IsAdobeAnalyticsAvailableInput>;
};


export type QueryIsProductFinderAvailableArgs = {
  input?: InputMaybe<IsProductFinderAvailableInput>;
};


export type QueryIsProductGroupHasProductFinderArgs = {
  input?: InputMaybe<IsProductGroupHasProductFinderInput>;
};


export type QueryListUserArgs = {
  input?: InputMaybe<PaginationInput>;
};


export type QuerySearchArgs = {
  input: SearchInput;
};


export type QuerySearchSuggestionsArgs = {
  input: SearchSuggestionsInput;
};


export type QuerySearchUsersArgs = {
  input?: InputMaybe<SearchUsersInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RenameFavoriteFolderDataOutput = {
  __typename?: 'RenameFavoriteFolderDataOutput';
  versionId: Scalars['String']['output'];
};

export type RenameFavoriteFolderInput = {
  countryCode: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  newFolderName: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type RenameFavoriteFolderOutput = {
  __typename?: 'RenameFavoriteFolderOutput';
  data?: Maybe<RenameFavoriteFolderDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type RenameFavoriteItemDataOutput = {
  __typename?: 'RenameFavoriteItemDataOutput';
  versionId: Scalars['String']['output'];
};

export type RenameFavoriteItemInput = {
  countryCode: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  newItemName: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type RenameFavoriteItemOutput = {
  __typename?: 'RenameFavoriteItemOutput';
  data?: Maybe<RenameFavoriteItemDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Rootline = {
  __typename?: 'Rootline';
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SaveProductComparisonDataOutput = {
  __typename?: 'SaveProductComparisonDataOutput';
  productComparison: ProductComparison;
  versionId: Scalars['String']['output'];
};

export type SaveProductComparisonInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  productIds: Array<InputMaybe<Scalars['Int']['input']>>;
  versionId?: InputMaybe<Scalars['String']['input']>;
};

export type SaveProductComparisonOutput = {
  __typename?: 'SaveProductComparisonOutput';
  data?: Maybe<SaveProductComparisonDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type Search = {
  __typename?: 'Search';
  accessories?: Maybe<CategorizedSearchAccessories>;
  content?: Maybe<CategorizedSearchContents>;
  detergents?: Maybe<CategorizedSearchDetergents>;
  hg?: Maybe<CategorizedSearchHg>;
  old?: Maybe<CategorizedSearchOld>;
  pro?: Maybe<CategorizedSearchPro>;
};

export type SearchContent = {
  __typename?: 'SearchContent';
  headline?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type SearchInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  q: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type SearchSuggestions = {
  __typename?: 'SearchSuggestions';
  data?: Maybe<Array<Maybe<ProductItem>>>;
};

export type SearchSuggestionsInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  q: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type SearchUsersInput = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<SearchUsersWhereInput>;
};

export type SearchUsersOutput = {
  __typename?: 'SearchUsersOutput';
  data?: Maybe<Array<Maybe<UserProfile>>>;
  pagination?: Maybe<SearchUsersPaginationOutput>;
};

export type SearchUsersPaginationOutput = {
  __typename?: 'SearchUsersPaginationOutput';
  limit?: Maybe<Scalars['Int']['output']>;
  paginationToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchUsersWhereInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<InputMaybe<UserRole>>>;
};

export type StringifyProduct = {
  __typename?: 'StringifyProduct';
  accessoriesFilterableItems?: Maybe<FilterableItems>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  export?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  recommendedProducts?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Structure = {
  __typename?: 'Structure';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  export?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  sorting?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<StructureType>;
  year?: Maybe<Scalars['Int']['output']>;
};

export enum StructureType {
  Content = 'content',
  Product = 'product',
  Productgroup = 'productgroup'
}

export type SyncProductComparisonDataOutput = {
  __typename?: 'SyncProductComparisonDataOutput';
  productComparison: ProductComparison;
  versionId: Scalars['String']['output'];
};

export type SyncProductComparisonInput = {
  countryCode: Scalars['String']['input'];
  languageCode: Scalars['String']['input'];
  productIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type SyncProductComparisonOutput = {
  __typename?: 'SyncProductComparisonOutput';
  data?: Maybe<SaveProductComparisonDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type SyncUserFavoriteDataOutput = {
  __typename?: 'SyncUserFavoriteDataOutput';
  folders: Array<FavoriteFolder>;
  items: Array<FavoriteItem>;
  versionId: Scalars['String']['output'];
};

export type SyncUserFavoriteFolderInput = {
  country: Scalars['String']['input'];
  id: Scalars['String']['input'];
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type SyncUserFavoriteInput = {
  countryCode: Scalars['String']['input'];
  folders: Array<SyncUserFavoriteFolderInput>;
  items: Array<SyncUserFavoriteItemInput>;
  languageCode: Scalars['String']['input'];
};

export type SyncUserFavoriteItemInput = {
  country: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parameter?: InputMaybe<FavoriteItemParameterInput>;
  payload: FavoriteItemPayloadInput;
  type: FavoriteItemTypeEnum;
};

export type SyncUserFavoriteOutput = {
  __typename?: 'SyncUserFavoriteOutput';
  data?: Maybe<SyncUserFavoriteDataOutput>;
  errorCode?: Maybe<ErrorCodeEnum>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  isSuccess: Scalars['Boolean']['output'];
};

export type TechData = {
  __typename?: 'TechData';
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Text = {
  __typename?: 'Text';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Translation = {
  __typename?: 'Translation';
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  export?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type Url = {
  __typename?: 'URL';
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UpdateUserSalesRepresentativeId = {
  salesRepresentativeId: Scalars['String']['input'];
};

export type UpdateUserSalesRepresentativeIdOutput = {
  __typename?: 'UpdateUserSalesRepresentativeIdOutput';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserComparisonMetadata = {
  __typename?: 'UserComparisonMetadata';
  country: Scalars['String']['output'];
  language: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  versionId?: Maybe<Scalars['String']['output']>;
};

export type UserFavoriteMetadata = {
  __typename?: 'UserFavoriteMetadata';
  country: Scalars['String']['output'];
  language: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  versionId?: Maybe<Scalars['String']['output']>;
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  hasSaleRepresentativeId?: Maybe<Scalars['Boolean']['output']>;
  isDashboardAccessible?: Maybe<Scalars['Boolean']['output']>;
};

export type UserNotifications = {
  __typename?: 'UserNotifications';
  popUpInfo?: Maybe<PopUpInfoNotification>;
};


export type UserNotificationsPopUpInfoArgs = {
  input?: InputMaybe<PopUpInfoNotificationInput>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  email?: Maybe<Scalars['String']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UserRole>;
  salesRepresentativeId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum UserRole {
  Admin = 'Admin',
  SalesRepresentative = 'SalesRepresentative',
  Standard = 'Standard'
}

export type UserSelfDeleteInput = {
  accessToken: Scalars['String']['input'];
};

export type UserSelfDeleteOutput = {
  __typename?: 'UserSelfDeleteOutput';
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', adminDeleteUser?: { __typename?: 'AdminDeleteUserOutput', isSuccess?: boolean | null, reasons?: Array<string | null> | null } | null };

export type ListUsersQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  paginationToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListUsersQuery = { __typename?: 'Query', listUser?: { __typename?: 'ListUserOutput', data?: Array<{ __typename?: 'UserProfile', email?: string | null, lastLoginAt?: string | null, name?: string | null, role?: UserRole | null, salesRepresentativeId?: string | null, userId?: string | null, isEnabled?: boolean | null } | null> | null, pagination?: { __typename?: 'Pagination', limit?: number | null, paginationToken?: string | null } | null } | null };

export type MyUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyUserProfileQuery = { __typename?: 'Query', getUserProfile?: { __typename?: 'UserProfile', email?: string | null, lastLoginAt?: string | null, name?: string | null, role?: UserRole | null, salesRepresentativeId?: string | null, userId?: string | null } | null, getUserMetadata?: { __typename?: 'UserMetadata', isDashboardAccessible?: boolean | null, hasSaleRepresentativeId?: boolean | null } | null };

export type SearchUsersQueryVariables = Exact<{
  role?: InputMaybe<Array<InputMaybe<UserRole>> | InputMaybe<UserRole>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  paginationToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers?: { __typename?: 'SearchUsersOutput', data?: Array<{ __typename?: 'UserProfile', isEnabled?: boolean | null, userId?: string | null, name?: string | null, email?: string | null, lastLoginAt?: string | null, role?: UserRole | null, salesRepresentativeId?: string | null } | null> | null, pagination?: { __typename?: 'SearchUsersPaginationOutput', limit?: number | null, total?: number | null, paginationToken?: string | null } | null } | null };

export type AdminUpdateUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  role?: InputMaybe<UserRole>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  salesRepresentativeId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AdminUpdateUserMutation = { __typename?: 'Mutation', adminUpdateUser?: { __typename?: 'AdminUpdateUserOutput', reasons?: Array<string | null> | null, isSuccess?: boolean | null } | null };


export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: String!) {
  adminDeleteUser(input: {userId: $userId}) {
    isSuccess
    reasons
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ListUsersDocument = gql`
    query ListUsers($limit: Int!, $paginationToken: String) {
  listUser(input: {limit: $limit, paginationToken: $paginationToken}) {
    data {
      email
      lastLoginAt
      name
      role
      salesRepresentativeId
      userId
      isEnabled
    }
    pagination {
      limit
      paginationToken
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      paginationToken: // value for 'paginationToken'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables> & ({ variables: ListUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export function useListUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersSuspenseQueryHookResult = ReturnType<typeof useListUsersSuspenseQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const MyUserProfileDocument = gql`
    query MyUserProfile {
  getUserProfile {
    email
    lastLoginAt
    name
    role
    salesRepresentativeId
    userId
  }
  getUserMetadata {
    isDashboardAccessible
    hasSaleRepresentativeId
  }
}
    `;

/**
 * __useMyUserProfileQuery__
 *
 * To run a query within a React component, call `useMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
      }
export function useMyUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
        }
export function useMyUserProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
        }
export type MyUserProfileQueryHookResult = ReturnType<typeof useMyUserProfileQuery>;
export type MyUserProfileLazyQueryHookResult = ReturnType<typeof useMyUserProfileLazyQuery>;
export type MyUserProfileSuspenseQueryHookResult = ReturnType<typeof useMyUserProfileSuspenseQuery>;
export type MyUserProfileQueryResult = Apollo.QueryResult<MyUserProfileQuery, MyUserProfileQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($role: [UserRole], $searchText: String, $isEnabled: Boolean, $limit: Int, $paginationToken: String) {
  searchUsers(
    input: {searchText: $searchText, where: {roles: $role, isEnabled: $isEnabled}}
    pagination: {limit: $limit, paginationToken: $paginationToken}
  ) {
    data {
      isEnabled
      userId
      name
      email
      lastLoginAt
      role
      salesRepresentativeId
    }
    pagination {
      limit
      total
      paginationToken
    }
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      role: // value for 'role'
 *      searchText: // value for 'searchText'
 *      isEnabled: // value for 'isEnabled'
 *      limit: // value for 'limit'
 *      paginationToken: // value for 'paginationToken'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export function useSearchUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersSuspenseQueryHookResult = ReturnType<typeof useSearchUsersSuspenseQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const AdminUpdateUserDocument = gql`
    mutation AdminUpdateUser($userId: String!, $role: UserRole, $isEnabled: Boolean, $salesRepresentativeId: String) {
  adminUpdateUser(
    input: {role: $role, userId: $userId, salesRepresentativeId: $salesRepresentativeId, isEnabled: $isEnabled}
  ) {
    reasons
    isSuccess
  }
}
    `;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *      isEnabled: // value for 'isEnabled'
 *      salesRepresentativeId: // value for 'salesRepresentativeId'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(AdminUpdateUserDocument, options);
      }
export type AdminUpdateUserMutationHookResult = ReturnType<typeof useAdminUpdateUserMutation>;
export type AdminUpdateUserMutationResult = Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>;